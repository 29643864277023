/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ol: "ol",
    li: "li",
    ul: "ul"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "1-agree",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-agree",
    "aria-label": "1 agree permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1 Agree"), "\n", React.createElement(_components.p, null, "You may order, access and use our Services Under this Agreement."), "\n", React.createElement(_components.h2, {
    id: "2-meaning-of-terms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-meaning-of-terms",
    "aria-label": "2 meaning of terms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2 Meaning of terms"), "\n", React.createElement(_components.p, null, "Unless otherwise defined in this Agreement, all capitalized terms used in this Agreement will have the meanings given to them on ", React.createElement(_components.a, {
    href: "definitions"
  }, "the definitions page"), "."), "\n", React.createElement(_components.h2, {
    id: "3-legal-relationship",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-legal-relationship",
    "aria-label": "3 legal relationship permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3 Legal relationship"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Our Service Offerings do not constitute any legal advice."), "\n", React.createElement(_components.li, null, "The relationship between you and us does not establish or constitute any attorney-client relationship."), "\n", React.createElement(_components.li, null, "Our services are for companies only."), "\n", React.createElement(_components.li, null, "Consumers are prohibited from buying our services."), "\n", React.createElement(_components.li, null, "Companies must indicate to us whether the company is in the EU or not."), "\n", React.createElement(_components.li, null, "EU companies must communicate to us their VAT identification number before the Trial period ends."), "\n"), "\n", React.createElement(_components.h2, {
    id: "4-provision-access-and-use",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-provision-access-and-use",
    "aria-label": "4 provision access and use permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "4 Provision, access and use"), "\n", React.createElement(_components.p, null, "Via a network, you can access and use our ", React.createElement(_components.a, {
    href: "definitions"
  }, "Service Offerings"), ", as you agree to in ", React.createElement(_components.a, {
    href: "a8-accessuse"
  }, "Annex 8"), "."), "\n", React.createElement(_components.h2, {
    id: "5-data-security",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-data-security",
    "aria-label": "5 data security permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5 Data Security"), "\n", React.createElement(_components.p, null, "We reasonably secure the processing of your Content, as you agree to in ", React.createElement(_components.a, {
    href: "a2-security"
  }, "Annex 2"), "."), "\n", React.createElement(_components.h2, {
    id: "6-personal-data",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#6-personal-data",
    "aria-label": "6 personal data permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "6 Personal data"), "\n", React.createElement(_components.p, null, "In order to perform our Services, we process your personal data, as you agree to in ", React.createElement(_components.a, {
    href: "a4-dataforagreement"
  }, "Annex 4"), "."), "\n", React.createElement(_components.h2, {
    id: "7-trial-period",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#7-trial-period",
    "aria-label": "7 trial period permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "7 Trial Period"), "\n", React.createElement(_components.p, null, "We offer a Trial Period at no charge, as you agree to in ", React.createElement(_components.a, {
    href: "a3-trialperiod"
  }, "Annex 3"), "."), "\n", React.createElement(_components.h2, {
    id: "8-fees-and-payment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#8-fees-and-payment",
    "aria-label": "8 fees and payment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "8 Fees and Payment"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "When the Trial Period ends, we will automatically charge you, except if you already cancelled by unsubscribing."), "\n", React.createElement(_components.li, null, "You pay by debit or credit card, and taxes/fees may be added to our prices, as you agree to in ", React.createElement(_components.a, {
    href: "a5-feesandpayment"
  }, "Annex 5"), "."), "\n", React.createElement(_components.li, null, "We issue to you invoices for our supply of services to you."), "\n", React.createElement(_components.li, null, "We make invoices available to you by electronic means."), "\n"), "\n", React.createElement(_components.h2, {
    id: "9-renewal",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#9-renewal",
    "aria-label": "9 renewal permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "9 Renewal"), "\n", React.createElement(_components.p, null, "Your current order Term will automatically renew for an equal order Term, except if you or we end the Services, as you agree to in ", React.createElement(_components.a, {
    href: "a11-renewal"
  }, "Annex 11"), "."), "\n", React.createElement(_components.h2, {
    id: "10-termination",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#10-termination",
    "aria-label": "10 termination permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "10 Termination"), "\n", React.createElement(_components.p, null, "You and we can terminate this Agreement, as described in ", React.createElement(_components.a, {
    href: "a6-termination"
  }, "Annex 6"), "."), "\n", React.createElement(_components.h2, {
    id: "11-suspension",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#11-suspension",
    "aria-label": "11 suspension permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "11 Suspension"), "\n", React.createElement(_components.p, null, "If you breach this Agreement or the law, then we can suspend your use of the Service, as you agree to in ", React.createElement(_components.a, {
    href: "a7-suspension"
  }, "Annex 7"), "."), "\n", React.createElement(_components.h2, {
    id: "12-your-warranties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#12-your-warranties",
    "aria-label": "12 your warranties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "12 Your warranties"), "\n", React.createElement(_components.p, null, "You warrant that:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "You have full legal power, authority and ability to enter into, and grant the rights under this Agreement,"), "\n", React.createElement(_components.li, null, "you will comply with the obligations under this Agreement,"), "\n", React.createElement(_components.li, null, "your grant of rights under this Agreement will not violate anyone’s rights, and"), "\n", React.createElement(_components.li, null, "you will not disclose Confidential Information without a separate, signed confidentiality agreement."), "\n"), "\n", React.createElement(_components.h2, {
    id: "13-our-warranties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#13-our-warranties",
    "aria-label": "13 our warranties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "13 Our warranties"), "\n", React.createElement(_components.p, null, "We warrant that:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "we have full legal power, authority and ability to enter into, and grant the rights under this Agreement, and,"), "\n", React.createElement(_components.li, null, "we will comply with the obligations under this Agreement."), "\n"), "\n", React.createElement(_components.h2, {
    id: "14-confidential-information",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#14-confidential-information",
    "aria-label": "14 confidential information permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "14 Confidential information"), "\n", React.createElement(_components.p, null, "If Confidential Information is exchanged in connection with this Agreement, the applicable confidentiality agreement is subject to this Agreement."), "\n", React.createElement(_components.h2, {
    id: "15-disclaimers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#15-disclaimers",
    "aria-label": "15 disclaimers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "15 Disclaimers"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Our Service Offerings are provided “AS IS”."), "\n", React.createElement(_components.li, null, "We make no representations and disclaim all warranties regarding the Service Offerings or third party content, except:"), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "to the extent prohibited by law, or"), "\n", React.createElement(_components.li, null, "to the extent that mandatory laws apply, and"), "\n", React.createElement(_components.li, null, "to the extent expressly set out in this Agreement."), "\n"), "\n", React.createElement(_components.h2, {
    id: "16-indemnification",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#16-indemnification",
    "aria-label": "16 indemnification permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "16 Indemnification"), "\n", React.createElement(_components.p, null, "You will defend, indemnify, and hold us harmless, as you agree to in ", React.createElement(_components.a, {
    href: "a9-indemnification"
  }, "Annex 9"), "."), "\n", React.createElement(_components.h2, {
    id: "17-liability",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#17-liability",
    "aria-label": "17 liability permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "17 Liability"), "\n", React.createElement(_components.p, null, "Our liability is limited, as you agree to in ", React.createElement(_components.a, {
    href: "a10-liability"
  }, "Annex 10"), "."), "\n", React.createElement(_components.h2, {
    id: "18-force-majeure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#18-force-majeure",
    "aria-label": "18 force majeure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "18 Force Majeure"), "\n", React.createElement(_components.p, null, "We will not be liable for any delay or failure to perform any obligation under this Agreement where the delay or failure results from any cause beyond our reasonable control."), "\n", React.createElement(_components.h2, {
    id: "19-communication-between-parties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#19-communication-between-parties",
    "aria-label": "19 communication between parties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "19 Communication between Parties"), "\n", React.createElement(_components.p, null, "Both parties agree to use the Notification Email Address for communication between us and you, as you agree in ", React.createElement(_components.a, {
    href: "a12-communication"
  }, "Annex 12"), "."), "\n", React.createElement(_components.h2, {
    id: "20-assignment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#20-assignment",
    "aria-label": "20 assignment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "20 Assignment"), "\n", React.createElement(_components.p, null, "You agree that any assignment or transfer of this Agreement without our prior written consent will be void."), "\n", React.createElement(_components.h2, {
    id: "21-entire-agreement",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#21-entire-agreement",
    "aria-label": "21 entire agreement permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "21 Entire Agreement"), "\n", React.createElement(_components.p, null, "This Terms of Service and its Annexes and additional applicable Attachments and additional applicable Transaction Documents and Data Processing Agreement (if applicable) and Joint Controller Agreement (if applicable) are the entire Agreement regarding transactions under this Terms of Service (together, the “Agreement”)."), "\n", React.createElement(_components.h2, {
    id: "22-interpretation-of-conflicting-terms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#22-interpretation-of-conflicting-terms",
    "aria-label": "22 interpretation of conflicting terms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "22 Interpretation of Conflicting Terms"), "\n", React.createElement(_components.p, null, "The Agreement supersede any course of dealing, discussions or representations between the parties, and shall be understood, as you agree in ", React.createElement(_components.a, {
    href: "a13-interpretation"
  }, "Annex 13"), "."), "\n", React.createElement(_components.h2, {
    id: "23-representation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#23-representation",
    "aria-label": "23 representation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "23 Representation"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "We are an independent contractor."), "\n", React.createElement(_components.li, null, "We are not your agent, joint venturer, partner, or fiduciary."), "\n", React.createElement(_components.li, null, "Our Business Partners who use or make available our Services are independent from us and unilaterally determine their prices and terms. We are not responsible for their actions, omissions, statements, or offerings."), "\n"), "\n", React.createElement(_components.h2, {
    id: "24-acceptance-of-agreement",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#24-acceptance-of-agreement",
    "aria-label": "24 acceptance of agreement permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "24 Acceptance of Agreement"), "\n", React.createElement(_components.p, null, "You agree to be bound by the terms and conditions of the Agreement by:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "clicking the “I Accept” button or the checkbox presented with these terms,"), "\n", React.createElement(_components.li, null, "signing the Agreement by hand,"), "\n", React.createElement(_components.li, null, "using any of the Service Offerings, or"), "\n", React.createElement(_components.li, null, "making any payment for the Service Offerings."), "\n"), "\n", React.createElement(_components.h2, {
    id: "25-modifications-to-agreement",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#25-modifications-to-agreement",
    "aria-label": "25 modifications to agreement permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "25 Modifications to Agreement"), "\n", React.createElement(_components.p, null, "We may modify the Agreement at any time by posting a modified Agreement at our website 🔗 ", React.createElement(_components.a, {
    href: "https://www.signatu.com"
  }, "https://www.signatu.com"), ", as you agree in ", React.createElement(_components.a, {
    href: "a14-agreementmodification"
  }, "Annex 14"), "."), "\n", React.createElement(_components.h2, {
    id: "26-dispute-resolution-applicable-law-and-jurisdiction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#26-dispute-resolution-applicable-law-and-jurisdiction",
    "aria-label": "26 dispute resolution applicable law and jurisdiction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "26 Dispute Resolution, Applicable Law and Jurisdiction"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "You agree to solve disputes, as you agree in ", React.createElement(_components.a, {
    href: "a15-lawjurisdiction"
  }, "Annex 15"), "."), "\n"), "\n", React.createElement(_components.h2, {
    id: "27-severability",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#27-severability",
    "aria-label": "27 severability permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "27 Severability"), "\n", React.createElement(_components.p, null, "If any provision of this Agreement is invalid, illegal or unenforceable, you agree that the remaining provisions remain in full force and effect."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
